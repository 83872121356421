<template>
  <div>
    <v-menu
      ref="selectDateAndTime"
      :close-on-content-click="false"
      offset-y
      v-model="dateMenu"
      transition="scale-transition"
      :max-width="datePickerWidth"
      min-width="auto"
    >
      <template v-slot:activator="{on, attr}">
        <v-text-field
          readonly
          :label="label"
          :filled="filled"
          :dense="dense"
          v-model="inputText"
          :placeholder="placeholder"
          :prepend-icon="noIcon ? null : (icon ? icon : 'mdi-calendar')"
          v-bind="attr"
          v-on="on"
          @click="openSelection"
          :clearable="clearable"
          :hide-details="hideDetails"
          :outlined="outlined"
          :hint="hint"
          :persistent-hint="persistentHint"
		  :disabled="disabled"
        />
      </template>
      <div v-click-outside="clickedOutside">
        <v-card
          v-if="dateMenu"
          :width="datePickerWidth"
          ref="dateTimeMenu"
        >
          <v-card
            tile
            flat
            dark
            color="primary"
          >
            <v-card-subtitle class="pb-0">
              {{ $t('selectedDateAndTime') }}
            </v-card-subtitle>
            <v-card-title
              class="pt-0"
              :class="showDateSelection ? 'pb-0' : ''"
            >
              {{ headerText }}
            </v-card-title>
          </v-card>
          <v-row>
            <v-col
              v-if="showDateSelection"
              class="pt-0"
              cols="12"
            >
              <v-date-picker
                ref="selectedDate"
                :value="selectedDate"
                full-width
                class="date-picker-style"
                @click:date="saveDate"
                style="min-height: 435px"
                show-adjacent-months
              />
            </v-col>
            <v-col
              v-if="showTimeSelection"
              class="py-0"
              cols="12"
            >
              <TimeSelector
                v-model="showTimeSelection"
                @selectTime="selectTime"
                :time="selectedTime"
                no-title
                @close="closeTimeSelection"
              />
            </v-col>
          </v-row>
        </v-card>
      </div>
    </v-menu>
  </div>
</template>

<script>
import TimeSelector from "@/components/common/TimeSelector"

export default {
	name: "DateAndTimeSelection",
	components: {
		TimeSelector
	},
	props: {
    value: {
      type: Number,
      default: 0
    },
    originalValue: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    hideDetails: Boolean,
    clearable: Boolean,
    outlined: Boolean,
    noIcon: Boolean,
    dense: Boolean,
    filled: Boolean,
    required: Boolean,
    persistentHint: Boolean,
	disabled: Boolean,
	},
	data() {
		return {
			dateMenu: false,
			showTimeSelection: false,
			showDateSelection: false,
			hasClickedOutSide: false,
		}
	},
	watch: {
		dateMenu(previousValue, currentValue) {
			if(!previousValue && currentValue && this.hasClickedOutSide) {
				this.timeString = this.originalValue
			}
		}
	},
	mounted() {
		this.$store.dispatch('getCurrentSystemTime')
	},
	methods: {
		saveDate(date) {
			this.selectedDate = date
			this.showDateSelection = false
			this.showTimeSelection = true
		},
		openSelection() {
			if(this.timeString === null) {
				let date = new Date(this.$store.state.systemTime)
				if(this.type === 'from') {
					this.timeString = date.setUTCHours(0, 0, 0)
				} else if(this.type === 'to') {
					this.timeString = date.setUTCHours(23, 59, 59)
				} else {
					this.timeString = date.getTime()
				}
			}
			this.showTimeSelection = false
			this.showDateSelection = true
		},
		selectTime(value) {
			this.hasClickedOutSide = false
			this.selectedTime = value
		},
		closeTimeSelection() {
			this.showTimeSelection = false
			this.dateMenu = false
		},
		clickedOutside() {
			this.hasClickedOutSide = true
		}
	},
	computed: {
		inputText: {
			get() {
				if(this.timeString) {
					let date = new Date(this.timeString).toISOString()
					return date.substr(0, 10) + ' ' + date.substr(11,5)
				} else {
					return null
				}
			},
			set(value) {
				if(value === null) {
					this.timeString = value
				}
			}
		},
		headerText() {
			let date = null
			if(this.timeString) {
				date = new Date(this.timeString).toISOString()
				return date.substr(0, 10) + ' ' + date.substr(11,5)
			} else {
				return this.$t('noDateSelected')
			}
		},
		datePickerWidth() {
			return this.$vuetify.breakpoint.smAndDown ? screen.availWidth : '500'
		},
		selectedDate: {
			get() {
				if(this.timeString) {
					return new Date(this.timeString).toISOString().substr(0,10)
				} else {
					return null
				}
			},
			set(value) {
				let time = this.selectedTime ? this.selectedTime.split(':') : null
				if(time !== null) {
					this.timeString = new Date(value).setUTCHours(parseInt(time[0]), parseInt(time[1]))
				} else {
					this.timeString = new Date(value).getTime()
				}
			}
		},
		selectedTime: {
			get() {
				if(this.timeString) {
					return new Date(this.timeString).toISOString().substr(11,5)
				} else {
					return null
				}
			},
			set(value) {
				let time = value ? value.toString().split(':') : null
				if(time !== null) {
					this.timeString = new Date(this.selectedDate).setUTCHours(time[0] * 1, time[1] * 1)
				} else {
					this.timeString = null
				}
			}
		},
		timeString: {
			get() {
				return this.value > 0 ? this.value : null
			},
			set(value) {
				this.$emit('setDateTime', value)
			}
		}
	}
}
</script>

<style scoped>
.date-picker-style {
	height: 400px;
	border-radius: 0;
}
</style>
