<template>
  <v-card flat>
    <v-card
      tile
      flat
      color="primary"
      dark
      v-if="!noTitle"
    >
      <v-card-subtitle class="pb-0 pt-4">
        {{ $t('currentlySelectedTime') }}
      </v-card-subtitle>
      <v-card-title class="pt-0 pb-4">
        {{ selectedTime[0].toString().padStart(2, '0') + ':' + selectedTime[1].toString().padStart(2, '0') }}
      </v-card-title>
    </v-card>
    <v-card-text class="py-0">
      <v-container
        class="px-0 py-1"
        id="time-selection-box"
      >
        <v-row>
          <v-col
            cols="6"
            class="overflow-y-auto text-center scroll-style"
            id="hours"
          >
            <div
              v-for="(hour) in hours"
              :key="hour"
              @click="selectedHour = parseInt(hour)"
              class="py-1"
              style="cursor: pointer;"
              :id="selectedHour === parseInt(hour) ? 'selected-hour': 'not-selected'"
              :ref="selectedHour === parseInt(hour) ? 'selected-hour': 'not-selected'"
              :style="selectedHour === parseInt(hour) ? 'color: #1976D2; background-color: #DCDCDC;': ''"
            >
              {{ hour }}
            </div>
          </v-col>
          <v-col
            cols="6"
            class="overflow-y-auto text-center scroll-style"
            id="minutes"
          >
            <div
              v-for="(minute) in minutes"
              :key="minute"
              @click="selectedMinute = parseInt(minute)"
              class="py-1"
              style="cursor: pointer;"
              :id="selectedMinute === parseInt(minute) ? 'selected-minute' : 'not-selected'"
              :ref="selectedMinute === parseInt(minute) ? 'selected-minute' : 'not-selected'"
              :style="selectedMinute === parseInt(minute) ? 'color: #1976D2; background-color: #DCDCDC;': ''"
            >
              {{ minute }}
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-row dense>
        <v-col>
          <v-btn
            block
            large
            @click="selectTime"
            color="primary"
          >
            {{ $t('select') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
	name: "TimeSelector",
	props: {
		time: {
			type: String,
			default: ''
		},
		value: {
			type: Boolean,
			default: false
		},
		noTitle: {
			type: Boolean
		}
	},
	data() {
		return {
			selected: null
		}
	},
	beforeMount() {
		if(this.time) {
			this.selected = this.time
		} else {
			this.selected = this.valueToDoubleDigitString(this.currentHour) + ':' + this.valueToDoubleDigitString(this.currentMinute)
		}
	},
	mounted() {
		// this.$nextTick(() => {
    //   this.scrollHourIntoView()
    //   this.scrollMinuteIntoView()
    // })
    setTimeout(() => {
      this.scrollHourIntoView()
      this.scrollMinuteIntoView()
    }, 100)
	},
  watch: {
		time(newValue) {
			if(newValue) {
				this.selected = newValue
			}
			this.$nextTick(() => {
        this.scrollHourIntoView()
        this.scrollMinuteIntoView()
      })
		}
	},
	methods: {
		valueToDoubleDigitString(value) {
			return value.toString().padStart(2, '0')
		},
		selectTime() {
			this.$emit('selectTime', this.selected)
			this.$emit('selectTimeWithButton', this.selected)
			this.$emit('close')
		},
    isInView(el) {
      const box = el.getBoundingClientRect()
      return box.top < window.innerHeight && box.bottom >= 0
		},
    scrollHourIntoView() {
      const scrollHour = document.getElementById('selected-hour')
      if(scrollHour) {
        scrollHour.scrollIntoView({ block: 'center' })
      }
    },
    scrollMinuteIntoView() {
      let scrollMinute = document.getElementById('selected-minute')
      if(scrollMinute) {
        scrollMinute.scrollIntoView({ block: 'center' })
      }
    },
	},
	computed: {
		scrollHour() {
			return document.getElementById('selected-hour')
		},
		scrollMinute() {
			return document.getElementById('selected-minute')
		},
    selectionBoxIsInView() {
      const selectionBox = document.getElementById('time-selection-box')
      if(typeof selectionBox !== 'undefined' && selectionBox !== null) {
        return this.isInView(selectionBox)
      }
      return false
    },
		selectedTime: {
			get() {
				if(this.selected) {
					if(this.selected.includes(':')) {
						return this.selected.split(':')
					} else {
						if(this.selected.length <= 2) {
							return this.selected + ':00'
						} else if(this.selected.length === 3) {
							let t = this.selected.toString().padStart(4, '0')
							return t.substr(0, 2) + ':' + t.substr(2,2)
						} else {
							return this.selected.substr(0, 2) + ':' + this.selected.substr(2,2)
						}
					}
				} else {
					return [this.currentHour, this.currentMinute]
				}
			},
			set(value) {
				this.selected = value
				this.$emit('selectTime', value)
			}
		},
		selectedHour: {
			get() {
				if(this.selectedTime[0]) {
					return this.selectedTime[0] * 1
				} else {
					return this.currentHour
				}
			},
			set(value) {
				this.selectedTime = this.valueToDoubleDigitString(value) + ':' + this.valueToDoubleDigitString(this.selectedMinute)
			}
		},
		selectedMinute: {
			get() {
				if(this.selectedTime[1]) {
					return this.selectedTime[1] * 1
				} else {
					return this.currentMinute
				}
			},
			set(value) {
				this.selectedTime = this.valueToDoubleDigitString(this.selectedHour) + ':' + this.valueToDoubleDigitString(value)
			}
		},
		hours() {
			return Array.apply(null, Array(24)).map((item, index) => { return index.toString().padStart(2, '0') })
		},
		minutes() {
			return Array.apply(null, Array(60)).map((item, index) => { return index.toString().padStart(2, '0')})
		},
		currentHour() {
			return new Date(this.currentTime * 1).getUTCHours()
		},
		currentMinute() {
			return new Date(this.currentTime * 1).getUTCMinutes()
		},
		currentTime() {
			const systemTime = this.$store.getters.systemTime
			if(!systemTime) {
				this.$store.dispatch('getCurrentSystemTime')
			}
			return this.$store.getters.systemTime
		}
	}
}
</script>

<style scoped>
.scroll-style {
	height: 250px;
	font-size: x-large;
}
::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 7px;
}
::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, .5);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
	box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
</style>
